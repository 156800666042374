<template>
  <div>
    <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">List</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
              v-model="keyword"
              placeholder="Search"
              class="p-2 w-64 m-2"
              @submit="submitSearchKeyword"
          />
        </div>
        <md-divider></md-divider>
        <Table :items="getResults" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="Q_ID" md-numeric>{{item.question.q_id}}</md-table-cell>
            <md-table-cell md-sort-by="testId" md-label="TEST TITLE">{{ item.test.title }}</md-table-cell>
            <md-table-cell md-sort-by="testId" md-label="TEST TYPE">{{ item.test.type }}</md-table-cell>
            <md-table-cell md-sort-by="fileName " md-label="SPEAKING">
              {{ item.content_percentage }}
            </md-table-cell>
            <!-- <md-table-cell md-sort-by="fileType" md-label="READING">{{ item.reading }}</md-table-cell>
            <md-table-cell md-sort-by="usedFor" md-label="WRITING">{{ item.writing }}</md-table-cell>
            <md-table-cell md-sort-by="usedFor" md-label="LISTENING">{{ item.listening }}</md-table-cell> -->
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <!-- <md-table-cell md-sort-by="createdBy" md-label="CREATED BY" class="text-victoria">{{ item.created_by }}</md-table-cell> -->
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Active': 'Inactive' }}</md-table-cell>
            <md-table-cell>
              <span class="flex action">
                <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails(item.id)">visibility</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>
      </CardBody>
    </Card>
    <!--    <div class="flex justify-end align-center mt-8" v-if="materials.length > 20">-->
    <!--      <Paginate-->
    <!--          :start="paginate.start"-->
    <!--          :end="paginate.end"-->
    <!--          :total="paginate.total"-->
    <!--          :limit="paginate.limit"-->
    <!--      />-->
    <!--    </div>-->
    <!-- <Dialog>
      <component :content="content" :is="component"></component>
    </Dialog> -->
  </div>
</template>

<script>
import {
  Card,
  Table,
  // Dialog,
  CardBody,
  // Paginate,
  SearchBox,
  // AnswerSheetDetails
} from "@/components";
// import AnswerSheetDetails from "@/components/molecule/result/AnswerSheetdetails";
import cms from "@/data/cms";
import { query } from "@/utils/queryString";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  components: {
    Card,
    Table,
    // Dialog,
    // Paginate,
    CardBody,
    SearchBox,
    // AnswerSheetDetails
  },
  data() {
    return {
      currentIndex: 0,
      component: 'AnswerSheetDetails',
      content: '',
      types: cms.types,
      keyword: '',
      selectedKeyword: '',
      query: '?',
      visibility: true,
      pagination: true,
      selectedValue: null,
      itemIndex: null,
      status: '',
      searchParams : new URLSearchParams(),
      paginate: {
        start: 1,
        end: 20,
        total: 100,
        limit: 20
      },
      buttonFilters : cms.sections
    }
  },
  computed: {
    ...mapGetters({
      getResults: "result/getResults"
    })
  },
  watch: {
    keyword(oldvalue, newValue) {
      query.set('search', oldvalue)
      // For reseting search result
      if(oldvalue.length < newValue.length && oldvalue.length === 0) {
        this.actResultList(`?${query.get()}`);
      }
      // For submitting search api after 3 cherecter
      if(oldvalue.length >= 3) {
        this.actResultList(`?${query.get()}`);
      }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actResultList: "result/actResultList",
      actShowResult: "result/actShowResult"
    }),

    onShowDetails(answerSheetId) {
      // this.component = 'AnswerSheetDetails';
      localStorage.setItem('answer_sheet_id', answerSheetId);
      localStorage.setItem('answer_bottom_hide', false);
      this.$router.push({
        name: 'student.evaluation',
      });
      this.actShowResult(answerSheetId);
      // this.dialog(true);
      
    },
    submitSearchKeyword() {
      this.actResultList(`?${query.set('search', this.keyword)}`);
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
  created() {
    console.log("it's running");
    console.log(this.getResults);
    let user_id = localStorage.getItem('user_id');
    this.actResultList(`?user_id=${user_id}`); // pass emtpy query value for getting all data
  },
}
</script>